import React, { useContext } from 'react';

import { FormContext } from '../context';

import './checkbox.css';

import loadComponents from '../../Loadable';

const Label = loadComponents('form-label');

export default function Checkbox({ field }) {
  const { values, handleCheck } = useContext(FormContext);
  return (
    <label
      className={`checkbox${values[field.id] ? ' active' : ''}`}
      htmlFor={field.id}
    >
      <input
        onChange={handleCheck}
        {...field}
        style={{
          position: `absolute`,
          opacity: 0,
          zIndex: -1,
        }}
        defaultChecked={values[field.id]}
      />
      <Label label={field.label} required={field.required} />
    </label>
  );
}
